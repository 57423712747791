import React, { useEffect } from "react";
import styles from "./verification.module.css";
import { useAccountVerificationMutation } from "../../features/api/user/userApi";
import { Loader } from "../common/loader/Loader";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../common/errorMessage/ErrorMessage";

export const Verification = () => {
  const navigate = useNavigate();

  const verifyAcc = async (token) => {
    try {
      await verifyAccount(token).unwrap();
      redirectLogin();
    } catch (err) {
      redirectSignUp();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get("token");

    verifyAcc(tokenParam);
  }, []);

  const [verifyAccount, { isLoading, isSuccess, isError, error }] =
    useAccountVerificationMutation();

  const redirectSignUp = () => {
    setTimeout(() => {
      navigate("/sign-up");
    }, 500000);
  };

  const redirectLogin = () => {
    setTimeout(() => {
      navigate("/login");
    }, 500000);
  };

  let content = null;

  if (isLoading) content = <Loader />;
  if (isSuccess)
    content = <p className={styles.success_msg}>Votre compte est vérifié</p>;
  if (isError)
    content = error?.data?.errorMessage ? (
      <p className={styles.err_msg}>{error.data.errorMessage}</p>
    ) : (
      <ErrorMessage />
    );

  return content;
};
