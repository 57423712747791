import React, { useEffect, useState } from "react";
import styles from "./filter.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSize,
  setPrice,
  setTitle,
} from "../../../features/filter/filterSlice";
import { useDebounce } from "../../../customHooks/debounce";
import { CustomDropdown } from "../../common/customDropdown/CustomDropdown ";
export const Filter = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.filters);
  const [selectedPrice, setSelectedPrice] = useState(state.maxPrice);
  const debounceValue = useDebounce(selectedPrice);

  // price -
  const min = 20;
  const max = 500;

  // size -
  const sizes = [50, 75, 100, 110];

  //title -
  // const titles = ['Lilium', 'Desert night', 'Twilight']
  const titles = [
    "tous",
    "cleo",
    "octivia",
    "obsede",
    "mirabelle",
    "malcolm",
    "le tresor",
    "lilium",
    "desert night",
    "twilight",
  ];
  const options = titles.map((title) => ({
    label: title === "tous" ? "Tous" : title,
    value: title === "tous" ? " " : title,
  }));

  const handleFilterByPrice = (value) => {
    setSelectedPrice(value);
  };

  useEffect(() => {
    dispatch(setPrice(debounceValue));
  }, [dispatch, debounceValue]);

  const handleFilterBySize = (value) => {
    dispatch(setSize(value));
  };
  const handleFilterByName = (query) => {
    dispatch(setTitle(query));
  };
  return (
    <div className={styles.filter_container}>
      <section className={styles.price}>
        <label>Prix</label>
        <input
          type="range"
          id="price"
          name="price"
          min="20"
          max="500"
          defaultValue={max}
          onChange={(e) => handleFilterByPrice(e.target.value)}
          className={state.maxPrice < 1000 ? styles.selected : ""}
        />
        <div>
          <p>{min}€</p>
          <p>{state.maxPrice}€</p>
          <p>{max}€</p>
        </div>
      </section>
      <section className={styles.size}>
        <div>
          <p>Taille</p>
          <ul>
            <li
              key={"all"}
              onClick={() => handleFilterBySize("")}
              className={state.size === "" ? styles.active : ""}
            >
              Tous
            </li>
            {sizes.map((data, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => handleFilterBySize(data)}
                  className={state.size === data ? styles.active : ""}
                >
                  {data}ml
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <section className={styles.title}>
        <p htmlFor="title">Titre</p>
        <CustomDropdown
          options={options}
          placeholder="Tous"
          handleFilterByName={handleFilterByName}
        />
      </section>
    </div>
  );
};
