import styles from "./productCart.module.css";
import { Product } from "../product/Product";
import { motion, AnimatePresence } from "framer-motion";
import {
  productsApi,
  useGetProductsQuery,
} from "../../../features/api/products/productsApi";
import { useDispatch, useSelector } from "react-redux";
import { NoProduct } from "../noProduct/NoProduct";
import { Loader } from "../../common/loader/Loader";
import { ErrorMessage } from "../../common/errorMessage/ErrorMessage";

export const ProductCart = ({ category = "" }) => {
  const state = useSelector((state) => state.filters);
  const dispatch = useDispatch();
  const {
    data: productPage,
    isLoading,
    isError,
  } = useGetProductsQuery({
    page: 0,
    size: state.size,
    name: state.title,
    price: state.maxPrice,
    category: category,
  });

  const getNextPage = () => {
    dispatch(
      productsApi.endpoints.getMoreProducts.initiate({
        page: productPage?.number + 1,
        size: state.size,
        name: state.title,
        price: state.maxPrice,
        category: category,
      })
    );
  };

  //what to render -
  let content = null;
  if (isLoading) {
    content = <Loader />;
  }
  if (isError) {
    content = <ErrorMessage />;
  }
  if (!isError && !isLoading && productPage.content?.length === 0) {
    content = <NoProduct />;
  }

  if (!isError && !isLoading && productPage.content?.length > 0) {
    content = (
      <motion.div layout className={styles.product_cart_container}>
        <AnimatePresence>
          {productPage.content.map((product, idx) => {
            return <Product key={idx} product={product} />;
          })}
        </AnimatePresence>

        {!productPage?.last && (
          <section className={styles.btn_container}>
            <button className={styles.btn_see_more} onClick={getNextPage}>
              Voir plust de produits
            </button>
          </section>
        )}
      </motion.div>
    );
  }
  return content;
};
