import React from "react";

export const CGU = () => {
  return (
    <div>
      <h3>CONDITIONS GENERALES D’UTILISATION</h3>
      <section>
        <section>
          <h4>1. Objet du Site </h4>
          <p>
            Le site de JFrag (ci-après le « Site ») est un site de commerce
            électronique qui propose à la vente des parfums des accessoires à
            des internautes ou utilisateurs naviguant sur le Site (ci-après
            le(s) « Visiteur(s) »). Ce Site est accessible par le réseau
            Internet à l’adresse www.jfrag.com;
          </p>
        </section>
        <section>
          <h4>2. Données personnelles et Cookies </h4>
          <p>
            JFrag collecte des données à caractère personnel dans le cadre du
            présent Site. Pour en savoir plus sur les pratiques de JFrag en
            matière de collecte, utilisation et communication de données
            personnelles ainsi que les dispositions relatives à l'exercice des
            droits les Visiteurs sont invités à consulter la politique « Données
            Personnelles et Cookies » du présente Site.
          </p>
        </section>
        <section>
          <h4>3. Propriété intellectuelle </h4>
          <h5>3.1. Site</h5>
          <p>
            Le Site ainsi que tout logiciel utilisé nécessairement en relation
            avec celui-ci peuvent contenir des informations confidentielles
            ainsi que des données protégées par le droit de la propriété
            intellectuelle. Ainsi, sauf mention contraire, les droits de
            propriété intellectuelle sur les documents et toute donnée de toute
            nature contenus dans le Site et chacun des éléments composant le
            Site (images, illustrations, sons, textes, éléments graphiques,
            charte...), y compris les logiciels, bases de données et newsletters
            sont la propriété exclusive de JFrag (ci-après le « Contenu »),
            celle-ci ne concédant aucune licence, ni aucun droit autre que celui
            de consulter le Site. La reproduction de tout ou partie du Contenu
            est seulement autorisée aux fins exclusives d’information pour un
            usage personnel et privé, toute reproduction et toute utilisation de
            copies réalisées à d’autres fins de quelque manière que ce soit et
            sous quelque forme que ce soit étant expressément interdite. Il est
            également interdit de copier, modifier, créer une œuvre dérivée,
            assembler, décompiler (à l’exception des cas prévus par la loi),
            vendre, attribuer, sous-licencier ou transférer de quelque manière
            que ce soit tout droit afférant au Contenu. Il est également
            interdit de modifier tout ou partie du Contenu et notamment le
            logiciel ou d’utiliser des versions modifiées des logiciels en vue
            notamment d’obtenir un accès non autorisé au service et d’accéder au
            Site par un autre moyen que l’interface qui est fournie au Visiteur
            par JFrag à cet effet.
          </p>
        </section>
        <section>
          <h4>3.2. Marques</h4>
          <p>
            Il est rappelé que la société Jahanara Fragrance est propriétaire de
            la marque JFrag et de ses logos. Le Visiteur reconnaît les droits
            exclusifs de JFrag sur la marque JFrag et s’interdit d’en faire un
            usage quelconque et plus généralement de porter atteinte aux droits
            de propriété intellectuelle de JFrag. JFrag se réserve le droit de
            demander des dommages et intérêts en cas de contrefaçon et plus
            généralement d’atteinte à ses droits de propriété intellectuelle.
          </p>
        </section>
        <section>
          <h4>3.3. Liens hypertexte </h4>
          <p>
            Le Site peut inclure des liens vers d’autres sites Internet ou
            d’autres sources Internet. Dans la mesure où JFrag ne peut contrôler
            ces sites et ces sources externes, JFrag ne peut être tenue pour
            responsable de la mise à disposition de ces sites et sources
            externes, et ne peut supporter aucune responsabilité quant au
            contenu, publicités, produits, services ou toute autre information
            ou donnée disponible sur ou à partir de ces sites ou sources
            externes. De plus, JFrag ne pourra être tenue responsable de tous
            dommages ou pertes avérés ou allégués consécutifs ou en relation
            avec l’utilisation ou le fait d’avoir fait confiance au contenu, à
            des biens ou des services disponibles sur ces sites ou sources
            externes. Toute création de liens vers le Site de JFrag, tout
            encadrement (framing) du Site, et plus généralement toute
            utilisation d’un élément composant le Site, est soumise à
            l’autorisation préalable et expresse de JFrag qui pourra être
            révoquée à tout moment à sa seule discrétion. JFrag se réserve le
            droit de (i) demander la suppression de tout lien vers le Site qui
            n’aurait pas été, ou ne serait plus, autorisé et de (ii) demander
            des dommages et intérêts en réparation du préjudice subi de ce fait.
          </p>
        </section>
        <section>
          <h4>4. Comportement des Visiteurs </h4>
          <p>
            Chaque Visiteur se doit d’utiliser Internet en responsable avec
            respect et courtoisie à l’égard des droits des autres utilisateurs
            d’Internet. A ce titre, le Visiteur, en accédant au Site s’interdit
            notamment de: - télécharger vers le Site, afficher, envoyer par
            courrier électronique ou transmettre par tout autre moyen un contenu
            illégal, préjudiciable, menaçant, injurieux, harcelant, délictuel,
            diffamatoire, vulgaire, obscène, contraire aux bonnes mœurs, portant
            atteinte à la vie privée d’une personne, en ce compris son droit à
            l’image, odieux ou désobligeant ou choquant d’un point de vue
            racial, ethnique ou autre ; - télécharger vers le Site, afficher,
            envoyer par courrier électronique ou transmettre par tout autre
            moyen des éléments publicitaires ou promotionnels non sollicités ou
            non autorisés, des "imprimés publicitaires", des "informations
            mensongères", des "chaînes de lettres", ou toute autre forme de
            sollicitation ; - télécharger vers le Site, afficher, envoyer par
            courrier électronique ou transmettre par tout autre moyen tout
            élément contenant des virus logiciels ou autres codes, fichiers ou
            programmes informatiques conçus pour interrompre, détruire ou
            limiter la fonctionnalité de tout logiciel ou matériel informatique
            ou de tout équipement de télécommunication ; - perturber ou
            interrompre le Site ou les serveurs ou réseaux connectés au Site, ou
            enfreindre les exigences, procédures, règles ou réglementations des
            réseaux connectés au Site ; - essayer de porter atteinte au service
            de tout utilisateur, hébergeur ou réseau, ce qui comprend, sans
            exhaustivité aucune, le fait d’exposer le Site à un virus, de créer
            une saturation, d’inonder le serveur, de saturer la messagerie
            d’e-mails ou encore de falsifier tout en-tête du paquet
            d’informations du protocole TCP/IP ou n’importe quelle partie des
            informations de l’en-tête figurant dans tout e-mail ; - accéder aux
            données qui ne sont pas destinées au Visiteur ou entrer dans un
            serveur / un compte auquel le Visiteur n’est pas autorisé à avoir
            accès ; - tenter de sonder, de scruter ou de tester la vulnérabilité
            d’un système ou d’un réseau, ou encore d’enfreindre les mesures de
            sécurité ou d’authentification sans en avoir reçu l’autorisation ; -
            usurper l’identité d’une autre personne ; - mener une activité ou
            inciter une tierce personne à mener une activité illégale ou toute
            autre activité qui porterait atteinte aux droits de JFrag , de ses
            fournisseurs, partenaires, distributeurs, de ses annonceurs ou de
            tout autre Visiteur ; - transmettre ou transférer (par quelque moyen
            que ce soit) des informations ou logiciels dérivés du Site notamment
            à d’autres pays ou à certains ressortissants étrangers en violation
            d’une loi ou réglementation nationale ou internationale. En
            reconnaissant la nature globale du réseau Internet, chaque Visiteur
            s’engage à respecter toutes les règles et procédures locales et
            internationales relatives au comportement en ligne et à un contenu
            acceptable et notamment toutes les lois en vigueur concernant la
            transmission des données techniques. À tout moment et pour quelque
            raison que ce soit, JFrag peut mettre en œuvre tout moyen permettant
            de mettre un terme, et ce, sans préavis, à l’utilisation du Site par
            le Visiteur ou à un quelconque des services de JFrag pour toute
            conduite violant ces conditions d’utilisation, sans préjudice de
            tout dommage et intérêt que JFrag se réserve le droit de réclamer au
            Visiteur en cas de non-respect des présentes conditions générales
            d’utilisation du Site.
          </p>
        </section>

        <section>
          <h4>5. Responsabilité</h4>
          <h5>5.1. Accès et fonctionnement du Site</h5>
          <p>
            Le Visiteur fait son affaire personnelle de la mise en place des
            moyens informatiques et de télécommunications permettant l’accès au
            Site de JFrag et des connaissances nécessaires à l’utilisation
            d’Internet et l’accès au Site. Le Visiteur conserve à sa charge les
            frais de connexion et d’équipement liés à l’accès à Internet et à
            l’utilisation du Site de JFrag.
          </p>
        </section>

        <section>
          <h5>5.2. Contenus et sites tiers</h5>
          <p>
            Il est rappelé que la société Jahanara Fragrance est propriétaire de
            la marque JFrag et de ses logos. Le Visiteur reconnaît les droits
            exclusifs de Jahanara Fragrance sur la marque JFrag et s’interdit
            d’en faire un usage quelconque et plus généralement de porter
            atteinte aux droits de propriété intellectuelle de JFrag. JFrag se
            réserve le droit de demander des dommages et intérêts en cas de
            contrefaçon et plus généralement d’atteinte à ses droits de
            propriété intellectuelle.
          </p>
        </section>

        <section>
          <h4>6. Généralités</h4>
          <h5>6.1. Modifications</h5>
          <p>
            Jahanara Fragrance se réserve la possibilité de modifier et de
            mettre à jour les Conditions Générales d’Utilisation sans préavis.
            Pour être informé de ces éventuelles modifications, JFrag conseille
            au Visiteur de relire les CGU régulièrement. L’utilisation du Site
            sera soumise aux CGU en vigueur au moment de l’utilisation.
          </p>

          <h5>6.2. Intégralité</h5>
          <p>
            Dans l’hypothèse où l’une des clauses des présentes CGU serait nulle
            du fait d’un changement de législation, de réglementation ou par une
            décision de justice, cela ne saurait en aucun cas affecter la
            validité et le respect des autres clauses des CGU.
          </p>

          <h5>6.3. Loi applicable</h5>
          <p>Les présentes CGU sont soumises au droit français. </p>
          <p>
            SAUF EN CE QUI CONCERNE LES CONSOMMATEURS, TOUT LITIGE RELATIF A
            L’EXISTENCE, L’INTERPRETATION, OU L’EXECUTION DES PRESENTES
            CONDITIONS D'UTILISATION, MEME EN CAS DE PLURALITE DE DEFENDEURS,
            SERA, A DEFAUT D’ACCORD AMIABLE, DE LA COMPETENCE EXCLUSIVE DES
            TRIBUNAUX DE NANTERRE. CETTE ATTRIBUTION DE JURIDICTION VAUT
            NONOBSTANT PLURALITE DE DEFENDEURS OU APPEL EN GARANTIE, MEME POUR
            LES PROCEDURES D’URGENCE OU LES PROCEDURES CONSERVATOIRES EN REFERE
            OU PAR REQUETE.
          </p>
          <p>
            Pour tout renseignement, vous pouvez prendre contact en envoyant un
            courriel à : service@jfrag.com
          </p>
        </section>
      </section>
    </div>
  );
};
