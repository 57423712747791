import React from "react";

export const SM = () => {
  return (
    <div>
      <h3> SITE MAP</h3>
      <h4>1. Accueil (Home)</h4>
      <li>Page principale qui présente un aperçu de JFRAG.</li>

      <h4>2. À propos (About)</h4>
      <li>Information sur l'entreprise, la mission, la vision, et l'équipe.</li>

      <h4>3. Produits</h4>
      <li>Catalogue des produits offerts, si applicable.</li>
      <li>Sous-catégories </li>

      <h4>4. Politique de confidentialité (Privacy Policy)</h4>
      <li>
        Les déclarations de confidentialité expliquent comment JFRAG gère les
        données des utilisateurs.
      </li>

      <h4>5. Conditions Générales d'Utilisation (CGU)</h4>
      <li>
        Les règles et conditions générales d’utilisation du site et des services
        proposés.
      </li>

      <h4>6. Mentions légales (Legal Notices)</h4>
      <li>Les informations légales obligatoires concernant l'entreprise.</li>
    </div>
  );
};
