import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  info: null,
  isExpired: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      let loggedInUser = {
        accessToken: action.payload?.accessToken,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        email: action.payload?.email,
        phone: action.payload?.phone,
        address: action.payload?.address,
        deliveryAddress: action.payload?.deliveryAddress,
      };
      state.info = action.payload ? loggedInUser : null;
    },
    setReAuthToken: (state, action) => {
      if (state.info != null) state.info.accessToken = action.payload;
    },
    clearUser: (state) => {
      state.info = null;
    },
    refreshTokenExpired: (state) => {
      state.isExpired = true;
    },
    resetRefreshTokenExpired: (state) => {
      state.isExpired = false;
    },
    updateUser: (state, action) => {
      let updatedUserInfo = {
        accessToken: state.info?.accessToken,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        email: action.payload?.email,
        phone: action.payload?.phone,
        address: action.payload?.address,
        deliveryAddress: action.payload?.deliveryAddress,
      };
      state.info = updatedUserInfo;
    },
  },
});

export const {
  setUser,
  clearUser,
  updateUser,
  setReAuthToken,
  refreshTokenExpired,
  resetRefreshTokenExpired,
} = userSlice.actions;
export default userSlice.reducer;
