import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImgLazy = ({ src, alt, className, isLCP = false }) => {
  return isLCP ? (
    // Render a regular <img> tag for the LCP image (not lazy-loaded)
    <img
      className={className}
      src={src}
      alt={alt}
      loading="eager"
      fetchpriority="high"
    />
  ) : (
    // Lazy-load other images
    <LazyLoadImage className={className} src={src} alt={alt} effect="blur" />
  );
};

export default ImgLazy;
