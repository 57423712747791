import React from "react";
import styles from "./footer.module.css";
import contact from "../../../assests/footerIcons/contact.png";
import payment from "../../../assests/footerIcons/payment.png";
import delivery from "../../../assests/footerIcons/delivery.png";
import condition from "../../../assests/footerIcons/condition.png";
import meta from "../../../assests/footerIcons/meta.png";
import instagram from "../../../assests/footerIcons/instagram.png";
import tiktok from "../../../assests/footerIcons/tiktok.png";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <footer>
      <div className={styles.footer_container}>
        <div className={styles.information}>
          <section>
            <img src={payment} alt="paiment" />
            <h5>Paiment</h5>
            <NavLink to="/payment-method">Moyen de paiment</NavLink>
            <br />
            <NavLink to="/return-policy">Politique de retour</NavLink>
          </section>
          {/* ----------------- */}
          <section>
            <img src={delivery} alt="livraison" />
            <h5>Livraison</h5>
            <NavLink to="/delivery">Codition de livraison</NavLink>
            <br />
            <NavLink to="/product-return">Retourner un produit</NavLink>
          </section>
          {/* ----------------- */}
          <section>
            <img src={contact} alt="contact" />
            <h5>Contact</h5>
            <NavLink to="/general-condition">Contact général</NavLink>
            <br />
            <NavLink to="/sitemap">Sitemap</NavLink>
          </section>
          {/* ----------------- */}
          <section>
            <img src={condition} alt="condition" />
            <h5>Condition général</h5>
            <NavLink to="/legal">Mention légales</NavLink>
            <br />
            <NavLink to="/user-condition">Conditions d'utilisation</NavLink>
            <br />
            <NavLink to="/sales-condition">Conditions de vente</NavLink>
          </section>
          {/* ------------------- */}
        </div>
        <div className={styles.social}>
          <section>
            <a href="/">
              <img src={meta} alt="meta" />
            </a>
            <a href="/">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="">
              <img src={tiktok} alt="tiktok" />
            </a>
          </section>
          <section>
            <p className={styles.copyright}>
              &copy; jfrag.com 2024 | Tous droits réservés | SIRET - 985 334 614
              00015{" "}
            </p>
            <p className={styles.designation}>Jahanara Fragrance</p>
          </section>
        </div>
      </div>
    </footer>
  );
};
