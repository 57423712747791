import React from "react";

export const PP = () => {
  return (
    <div>
      <h3>POLITIQUE DE RETOUR</h3>
      <div>
        <section>
          <p>
            Chez <b>JFRAG</b> , nous nous engageons à fournir des produits de
            haute qualité. Si, pour une raison quelconque, vous n'êtes pas
            entièrement satisfait de votre achat, nous offrons des options de
            retour conformément aux conditions suivantes :
          </p>
          <h4>1. Droit de Rétractation</h4>
          <p>
            Conformément à la législation en vigueur, l'Acheteur dispose d'un
            délai de <b>14 jours </b> à compter de la date de réception de la
            commande pour exercer son droit de rétractation, sans avoir à
            justifier de motif ni à payer de pénalités. Toutefois, pour des
            raisons d'hygiène, seuls les{" "}
            <b>
              {" "}
              produits non utilisés, non ouverts, et dans leur emballage
              d'origine{" "}
            </b>{" "}
            seront acceptés pour un retour.
          </p>
          <h4>2. Conditions de Retour</h4>
          <p>Pour que votre retour soit accepté, veuillez vous assurer que :</p>
          <li>Le produit n'a pas été ouvert, utilisé ou endommagé.</li>
          <li>
            Le produit est retourné dans son <b> emballage d'origine </b> avec
            tous les accessoires ou échantillons, le cas échéant.
          </li>
          <li>
            Le retour est effectué dans un délai de <b> 14 jours </b> à compter
            de la réception de votre commande.
          </li>
          <p>
            <b>Note :</b> Les produits personnalisés ou les articles en
            promotion ne sont pas éligibles aux retours.
          </p>
          <h4>3. Procédure de Retour</h4>
          <p>Pour initier un retour, veuillez suivre les étapes suivantes :</p>
          <li>
            1. Contactez notre service client à l'adresse suivante :
            service@jfrag.com en indiquant votre numéro de commande et la raison
            du retour.
          </li>
          <li>
            2. Une fois que votre demande est approuvée, nous vous fournirons un
            numéro de retour ainsi que l'adresse à laquelle envoyer votre
            produit.
          </li>
          <li>
            3. Emballez soigneusement votre produit et incluez une copie de
            votre facture ou de votre bon de livraison avec votre numéro de
            retour.
          </li>
          <li>
            Les frais de retour sont à la charge de l'Acheteur, sauf en cas
            d'erreur de notre part (produit défectueux ou commande incorrecte).
          </li>
          <h4>4. Remboursement</h4>
          <p>
            Une fois que nous avons reçu et inspecté votre retour, nous vous
            informerons de l'approbation ou du refus de votre remboursement. Si
            le retour est approuvé, un remboursement sera effectué sur le même
            moyen de paiement utilisé lors de la commande, dans un délai de{" "}
            <b> 14 jours.</b>
          </p>
          <h4>5. Échanges</h4>
          <p>
            Les échanges sont uniquement possibles pour des produits défectueux
            ou envoyés par erreur. Si vous avez besoin d'échanger un produit,
            veuillez contacter notre service client à l'adresse suivante :
            service@jfrag.com.
          </p>
        </section>
      </div>
    </div>
  );
};
