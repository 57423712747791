import React from "react";
import { PromoProduct } from "./promoproduct/PromoProduct";
import styles from "./promoproducts.module.css";
import { NoData } from "../../common/noData/NoData";

export const PromoProducts = ({ products }) => {
  return (
    <div className={styles.promo_cart_container}>
      <h3>en promotion</h3>
      {products.length ? (
        <div className={styles.promo_cart}>
          <section className={styles.promo_cart_items}>
            {products[0] ? <PromoProduct product={products[0]} /> : null}
            {products[1] ? <PromoProduct product={products[1]} /> : null}
            {products[2] ? <PromoProduct product={products[2]} /> : null}
            {products[3] ? <PromoProduct product={products[3]} /> : null}
            {products[4] ? <PromoProduct product={products[4]} /> : null}
            {products[5] ? <PromoProduct product={products[5]} /> : null}
            {products[6] ? <PromoProduct product={products[6]} /> : null}
            {products[7] ? <PromoProduct product={products[7]} /> : null}
          </section>
          <div className={styles.link_more}>
            <a href="google.com">Et encore plus...</a>
          </div>
        </div>
      ) : (
        <NoData title={"produit"} />
      )}
    </div>
  );
};
