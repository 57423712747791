import React, { useEffect, useState } from "react";
import styles from "./signIn.module.css";
import { useLogInMutation } from "../../features/api/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import {
  resetRefreshTokenExpired,
  setUser,
} from "../../features/api/user/userSlice";
import { NavLink } from "react-router-dom";
const errorMessages = {
  400: "Votre mot de passe ou votre adresse électronique n'est pas correct",
  404: "Utilisateur n'existe pas",
  401: "Votre session a expiré, veuillez vous connecter à nouveau",
};
export const SignIn = () => {
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({
    userName: "",
    password: "",
  });
  const [logIn, { isLoading, isSuccess, isError, error }] = useLogInMutation();
  const { isExpired } = useSelector((state) => state.user);

  useEffect(() => {
    if (isExpired) {
      setTimeout(() => {
        dispatch(resetRefreshTokenExpired());
      }, 7000);
    }
  }, [isExpired]);
  const setLoginDetails = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (userDetails.userName && userDetails.password) {
      try {
        // Initiate the API call
        let data = await logIn(userDetails).unwrap();
        let loggedInUser = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          firstName: data.userDto.firstName,
          lastName: data.userDto.lastName,
          email: data.userDto.email,
          phone: data.userDto.phone,
          address: data.userDto.address,
          deliveryAddress: data.userDto.deliveryAddress,
        };
        dispatch(setUser(loggedInUser));
      } catch (err) {
        //do nothing -
      }
    }
  };
  const errorStatus = isExpired ? 401 : error?.status;
  const message = errorMessages[errorStatus];
  return (
    <div className={styles.sign_in_container}>
      <section className={styles.triangle_container}>
        <div></div>
        <div></div>
        <div></div>
        <p>J</p>
      </section>
      <form className={styles.sign_in_form} onSubmit={handleLogin}>
        <section>
          <div className={styles.sign_in_input_group}>
            <label htmlFor="userName">
              <span>E-mail</span>
              <span>:</span>
            </label>
            <input
              required
              name="userName"
              type="email"
              placeholder="Ex: jhondoe@mail.com"
              minLength={10}
              maxLength={100}
              onChange={setLoginDetails}
            />
          </div>
          <div className={styles.sign_in_input_group}>
            <label htmlFor="password">
              <span>Mot de passe</span>
              <span>:</span>
            </label>
            <input
              required
              name="password"
              type="password"
              autoComplete="on"
              onChange={setLoginDetails}
            />
          </div>
        </section>
        <section className={styles.signIn_btn_info}>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "En cour..." : "Se connecter"}
          </button>
          {isSuccess && <div>Login successful!</div>}

          {/* Render error messages if any */}
          {(isError || isExpired) && message && (
            <p
              className={`${styles.warning} ${
                errorStatus === 404 ? styles.user_not_found : ""
              }`}
            >
              {message}
            </p>
          )}
        </section>
        <ul className={styles.reset_registration}>
          <li>
            <NavLink to="/reset-password">Mot de passe oublié</NavLink>
          </li>
          <li>
            <NavLink to="/sign-up">S'inscrire</NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
};
