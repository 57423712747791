import React from "react";

export const PM = () => {
  return (
    <div>
      <h3>PAIEMENT</h3>
      <div>
        <section>
          <p>
            L’Acheteur peut régler sa commande sur le site, selon les modalités
            définies ci-après. L’Acheteur garantit à JFRAG qu'il dispose des
            autorisations nécessaires pour utiliser le moyen de paiement choisi
            lors de la commande.
          </p>
          <h4>1. Moyens de Paiement sur le Site</h4>
          <h5>1.1 Cartes de Paiement</h5>
          <p>
            Les cartes bancaires acceptées sur le site sont : CB, Visa,
            Mastercard, et American Express. Ces cartes doivent être émises par
            un établissement bancaire situé dans l'Union Européenne.
          </p>
          <p>
            <b>Fonctionnement : </b>Le paiement s’effectue en une seule fois par
            carte bancaire, permettant de régler directement la commande passée
            sur notre site.
          </p>
          <h5>1.2 Modalités de Paiement</h5>
          <p>
            Toute commande doit être réglée en euros, toutes taxes et
            contributions incluses. Les éventuels frais bancaires liés au
            paiement sont à la charge de l'Acheteur, même en cas de
            remboursement. JFRAG se réserve le droit de suspendre ou d'annuler
            toute commande en cas de non-paiement, de paiement partiel, ou en
            cas d'incident de paiement, y compris pour des commandes
            antérieures, et ce à tout moment durant le processus de commande ou
            de livraison.
          </p>
          <h5>1.3 Sécurité des Opérations de Paiement</h5>
          <p>
            Afin d'assurer la sécurité des paiements par carte bancaire sur le
            site, l'Acheteur devra fournir le cryptogramme visuel (CVV) situé au
            verso de sa carte bancaire.
          </p>
          <p>
            Dans le cadre de la lutte contre la fraude sur Internet, certaines
            informations relatives à la commande pourront être transmises à des
            tiers autorisés par la loi, ou désignés par JFRAG, afin de vérifier
            l'identité de l'Acheteur, la validité de la commande, ainsi que le
            mode de paiement utilisé et la livraison prévue.
          </p>
          <p>
            Si nécessaire, JFRAG se réserve le droit de demander une photocopie
            de la carte d'identité de l'Acheteur et/ou toute autre information
            permettant de confirmer son identité. Chaque Acheteur bénéficie d'un
            droit d'accès, de rectification, et de suppression de ses données
            personnelles conformément aux dispositions de l'article 2 de notre
            politique de confidentialité sur les « Données personnelles et
            Cookies ».
          </p>
          <h5>1.4 Outil de Paiement Sécurisé</h5>
          <p>
            JFRAG utilise un système de paiement sécurisé. La sécurité des
            transactions repose sur l’authentification de l’Acheteur et la
            confidentialité des informations échangées. Ce système utilise des
            techniques de cryptographie reconnues et conformes aux normes
            bancaires en vigueur en France.
          </p>
        </section>
      </div>
    </div>
  );
};
