import React from "react";

export const Mentionlegal = () => {
  return (
    <div>
      <h3>MENTIONS LEGALES</h3>
      <section>
        Editeur : Le site JFrag est produit et édité par Jahanara Fragrance SAS,
        société par actions simplifiée, immatriculée au Registre du Commerce et
        des Sociétés le numéro 985 334 614 00015, dont le siège social est situé
        au :
      </section>
      <section>
        7 Av, Paul Cézanne, 95200 Sarcelles, France. E-mail : service@jfrag.com
      </section>
      <b>SIRET : 985 334 614 00015</b>
    </div>
  );
};
