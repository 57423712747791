import React from "react";
import { Filter } from "../../components/products/filter/Filter";
import { ProductCart } from "../../components/products/productCart/ProductCart";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";
import SEO from "../../components/common/seo/SEO";

export const Ladies = () => {
  return (
    <>
      <SEO
        title="JFrag | Meilleurs Parfums Femme - Luxe & Fragrances Haut de Gamme"
        description="Découvrez les meilleurs parfums de luxe pour femme chez JFrag. Sélection exclusive de fragrances florales, orientales et rares. Trouvez votre parfum d'exception dès maintenant !"
        keywords="Parfum femme, Meilleur parfums femme, Meilleur parfum de luxe femme, Eau de parfum femme, Parfums exclusifs femme, Parfum floral femme, 
        Parfums orientaux femme, Parfum de niche femme, Parfum rare femme"
      />

      <PageHeader title={"Perfum femme"} />
      <Filter />
      <ProductCart category={"femme"} />
    </>
  );
};
