import React, { useEffect } from "react";
import styles from "./hero.module.css";
import hero_img from "../../../assests/bg_hero.webp";
import most_sold from "../../../assests/wave.png";
import { useNavigate } from "react-router-dom";
import ImgLazy from "../../common/imgLazy/ImgLazy";

export const Hero = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Preload the background image
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = hero_img;
    link.as = "image"; // The resource type
    document.head.appendChild(link);
  }, []); // This effect runs only once when the component mounts

  return (
    <div className={styles.hero}>
      <div className={styles.hero_bg_txt}></div>
      <div className={styles.hero_bg_img}></div>
      <div className={styles.cta_container}>
        <section className={styles.cta}>
          <h1>La fraîcheur au delà de toute comparaison</h1>
          <p className={styles.slogan}>
            Une touche de magie dans chaque flacon de parfum
          </p>
          <button
            className={styles.btn_cta}
            onClick={() => navigate("/all-product")}
          >
            Decouvrez
          </button>
          <p className={styles.bg_txt}>PURITY</p>
        </section>
        <section className={styles.cta_product}>
          <ImgLazy
            className={styles.hero_bg}
            src={hero_img}
            alt="background"
            isLCP={true}
          />
          <div className={styles.most_sold_item}>
            <figure>
              <section>
                <h2>Wave</h2>
                <p>Eau de perfume</p>
                <p>Unisex</p>
                <figcaption>Plus vendu récement</figcaption>
              </section>
              <img src={most_sold} alt="most sold item" />
            </figure>
            <figure>
              <section>
                <h2>Wave</h2>
                <p>Eau de perfume</p>
                <p>Unisex</p>
                <figcaption>Plus vendu récement</figcaption>
              </section>
              <img src={most_sold} alt="most sold item" />
            </figure>
          </div>
        </section>
      </div>
    </div>
  );
};
