import React from "react";

export const CG = () => {
  return (
    <div>
      <h3>CONTACT GENERALES </h3>
      <h4>1. Coordonnées Générales :</h4>
      <li>Email général : service@jfrag.com</li>

      <h4>2. Support Client :</h4>
      <li>Email du support : support@jfrag.com</li>
    </div>
  );
};
