import React from "react";
import { ResetPasswordRequest } from "../../components/resetPasswordRequest/ResetPasswordRequest";

import { PageHeader } from "../../components/common/pageHeader/PageHeader";
export const ResetPassword = () => {
  return (
    <main>
      <PageHeader title={"Réinitialiser mot de passe"} />
      <ResetPasswordRequest />
    </main>
  );
};
