import React from "react";
import { Filter } from "../../components/products/filter/Filter";
import { ProductCart } from "../../components/products/productCart/ProductCart";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";
import SEO from "../../components/common/seo/SEO";

export const Gents = () => {
  return (
    <>
      <SEO
        title="JFrag | Meilleurs Parfums Homme - Luxe & Fragrances Haut de Gamme"
        description="Découvrez les meilleurs parfums de luxe pour homme chez JFrag. Sélection exclusive de fragrances rares, boisées et épicées. Achetez votre parfum d'exception dès maintenant !"
        keywords="Parfum homme, Meilleur parfum homme, Meilleur parfum de luxe homme, 
        Eau de parfum homme, Parfums exclusifs homme, Parfums boisés homme, Parfums épicés homme, Parfums artisanaux homme, Parfum de niche homme, Parfum rare homme"
      />

      <PageHeader title={"Perfum homme"} />
      <Filter />
      <ProductCart category={"homme"} />
    </>
  );
};
