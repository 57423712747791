import React from "react";
import { Filter } from "../../components/products/filter/Filter";
import { ProductCart } from "../../components/products/productCart/ProductCart";
import { PageHeader } from "../../components/common/pageHeader/PageHeader";
import SEO from "../../components/common/seo/SEO";

export const AllProduct = () => {
  return (
    <main>
      <SEO
        title="JFrag | Tous Nos Produits - Parfums de Luxe"
        description="Explorez notre collection exclusive de parfums de luxe en ligne. Boutique de parfumerie haut de gamme avec des parfums rares, artisanaux et de niche pour hommes et femmes. Achetez des fragrances premium et découvrez nos nouveautés."
        keywords="Parfums de luxe en ligne, Boutique de parfums haut de gamme, Acheter parfums exclusifs France, Collection de parfums de niche, Parfumerie de luxe française, Vente de parfums artisanaux, Parfums rares en ligne, Sélection de fragrances premium, Parfums haut de gamme pour hommes et femmes, Nouveautés parfums de luxe"
      />
      <PageHeader title={"Tous nous produits"} />
      <Filter />
      <ProductCart />
    </main>
  );
};
