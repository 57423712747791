import React from "react";
import { Hero } from "../../components/landing/hero/Hero";
import { NewProducts } from "../../components/landing/newproducts/NewProducts";
import { PromoProducts } from "../../components/landing/promoproducts/PromoProducts";
import { TrendingProducts } from "../../components/landing/trendingproducts/TrendingProducts";
import { useGetLandingPageProductsQuery } from "../../features/api/products/productsApi";
import { Loader } from "../../components/common/loader/Loader";
import { ErrorMessage } from "../../components/common/errorMessage/ErrorMessage";
import { NoData } from "../../components/common/noData/NoData";
import SEO from "../../components/common/seo/SEO";

export const Home = () => {
  const {
    data: productList,
    isLoading,
    isError,
  } = useGetLandingPageProductsQuery();

  let content = null;
  if (isLoading) {
    content = <Loader />;
  }
  if (isError) {
    content = <ErrorMessage />;
  }
  if (productList) {
    content = (
      <>
        <SEO
          title="JFrag | Parfumerie de Luxe en Ligne - Meilleurs Parfums pour Homme & Femme"
          description="Découvrez JFrag, la référence en parfumerie de luxe en ligne. Explorez une sélection premium de parfums rares et exclusifs pour hommes & femmes. Achetez dès maintenant !"
          keywords="Jfrag, Parfumerie de luxe, Acheter parfum en ligne, Parfum exclusif, Créateur de parfum de luxe, Meilleur parfum pour homme, Meilleur parfum pour femme, Parfum de niche, Premium fragrance, 
          Artisan fleuriste parfum de fleurs, Boutique de parfum en ligne, Eau de parfum, Eau de toilette"
        />

        <NewProducts products={productList?.newProductDtos} />
        <PromoProducts products={productList?.discountProductDtos} />
        <TrendingProducts products={productList?.trendingProductDtos} />
      </>
    );
  } else {
    content = <NoData title={"produit"} />;
  }

  return (
    <>
      <Hero />
      {content}
    </>
  );
};
